<template>
    <div class="setting-google-analytics">
        <edit :fs="googleAnalyticsFields" api="settings" id="google-analytics" title="Google Analytics Setting"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { googleAnalyticsFields } from "@/config";
export default {
    components: {
        Edit
    },
    computed: {
        googleAnalyticsFields() {
            return googleAnalyticsFields;
        }
    }
};
</script>
<style lang="less">
.setting-google-analytics {
    .md-card {
        max-width: 1000px;
        margin: auto;
    }
}
</style>


